import { isAfter, isWithinInterval, parseISO } from 'date-fns'
import { capitalizeFirstLetter, handleCodeableConcept } from '../helpers'

export function buildCoveragePeriod(resource: any, key: string): string {
  return resource ? resource.period[key] : ''
}

export function buildCoverageStatus(resource: any): string {
  const { start, end } = resource.period

  const today = new Date()
  if (!end) {
    return isAfter(today, parseISO(start)) ? 'Active' : 'N/A'
  }

  return isWithinInterval(today, { start: parseISO(start), end: parseISO(end) }) ? 'Active' : 'Inactive'
}

export function buildCoverageIdentifier(resource: any): string {
  // console.dir(resource)
  return resource ? resource.policyHolder.reference : ''
}


// export function buildCoverageSubId(resource: any): string {
//   return resource ? resource.subscriberId : ''
// }

export function buildCoverageSubId(resource: any): string {
  // if (resource && resource.subscriberId) {
  //   const parts = resource.subscriberId.split('-')
  //   if (parts.length >= 2) {
  //     return parts.slice(1).join('-')
  //   }
  // }w
  return resource.subscriberId
}

export function buildCoveragePayer(resource: any): string {
  const payor = resource?.type?.[0]?.coding?.[0]?.display ?? 'No Payor Listed'
  return payor
}

export function buildCoverageMemberID(resource: any): string {

  const memberId =
    resource && resource.identifier
      ? resource.identifier[0]?.value || 'N/A'
      : resource && resource.beneficiary && resource.beneficiary.reference
        ? resource.beneficiary.reference
        : 'N/A'


  return memberId
}


export function buildCoverageBeneficiary(resource: any): string {
  // if (resource && resource.beneficiary.reference) {
  //   const parts = resource.beneficiary.reference.split('-')
  //   if (parts.length >= 2) {
  //     return parts.slice(1).join('-')
  //   } else {
  //     return resource ? resource.beneficiary.reference : ''
  //   }
  // }

  const memberId = resource?.identifier[0]?.display ? resource?.identifier[0]?.display : resource.beneficiary.reference
  return memberId


}

// export function buildCoverageRelationship(resource: any): string {

//   //Old Method ---
//   // let relationType = resource && resource.relationship ? resource.relationship.text : ''
//   // relationType = relationType == '' && resource?.link[0]?.relation ? resource.link[0].relation : ''
//   // return relationType
//   //--------------

//   let relationType = resource && resource.relationship ? resource.relationship.text : ''
//   relationType = relationType === '' && resource?.link[0]?.relation ? resource.link[0].relation : ''

//   // Capitalize the first letter
//   return relationType.charAt(0).toUpperCase() + relationType.slice(1)
// }

// export function buildCoverageRelationship(resource: any): string {
//   const relationship = resource && resource.entry[0].resource.relationship ? resource.entry[0].resource.relationship.coding[0].display : 'No Relationship Listed'
//   return relationship
// }

export function buildCoverageRelationship(resource: any): string {
  if (!resource || !resource.relationship) {
    return 'No Relationship Listed'
  }

  const relationshipString = handleCodeableConcept(resource.relationship)
  return capitalizeFirstLetter(relationshipString)
}

export const sortCoveragesByStartDate = (coverages: any) => coverages.sort((a: any, b: any) => {
  const startDateA: Date | null = a.period ? new Date(a.period.start) : null
  const startDateB: Date | null = b.period ? new Date(b.period.start) : null

  if (startDateA && startDateB) {
    return startDateB.getTime() - startDateA.getTime()
  }

  if (startDateA) {
    return -1
  }

  if (startDateB) {
    return 1
  }
  return 0
})
