import React, { FC, useEffect, useState } from 'react'
import isAuthenticated from '../../routes/PrivateRoute'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { useGlobalState } from '../../hooks/store/useGlobalState'
import useProviderFhirResource from '../../hooks/admin/useProviderFhirResource'
import { useHistory, useParams } from 'react-router-dom'
import { OrganizationDetail, OrganizationList } from '../../components/FhirData/Organization'
import { PractitionerDetail, PractitionerList } from '../../components/FhirData/Practitioner'
import { NetworkList } from '../../components/FhirData/Network'
import PermissionGate from '../../components/PermissionGate'
import useUser from '../../hooks/authentication/useUser'

interface RouteParams {
    resourceId: string | undefined
}

/**
 *  NOTE: For now, only admins can view the provider directory. This will be updated in the future once it has gone through a redesign and is ready for public use. 
 *  As discussed by @tyler-gotz and @emilynorton and documented in JIRA ticket: https://teschglobal.atlassian.net/browse/PORTAL-723
 */
const ProviderList: FC = () => {
    const {
        providerResource,
        setProviderResource,
        providerSearchTerms,
        setProviderSearchTerms,
        setProvider,
        provider
    } = useGlobalState((state) => ({
        providerResource: state.providerResource,
        providerSearchTerms: state.providerSearchTerms,
        setProviderSearchTerms: state.setProviderSearchTerms,
        setProvider: state.setProvider,
        provider: state.provider,
        setProviderResource: state.setProviderResource
    }))

    const [viewPressed, setViewPressed] = useState(true)
    const [page, setPage] = useState(0)
    const [pagesOffset, setPagesOffset] = useState('')
    const [fhirPageState, setFhirPageState] = useState('')

    const [key, setKey] = useState(providerResource)

    const { resourceId } = useParams<RouteParams>()
    const history = useHistory()

    const handleChangeKey = (k: string | null) => {
        setKey(k || '')
        setProviderResource(k || '')
        handleClearForm()
        newSearch()
        if (resourceId) {
            history.push('/provider-list')
        }
    }

    const { data: user } = useUser()
    const { refetch: fetchResources, data: providers, isLoading, isSuccess, isFetching, remove, isError } = useProviderFhirResource({
        resource: key,
        pagesOffset,
        pageState: fhirPageState,
        searchTerms: providerSearchTerms,
    })

    const handleNextPage = () => {
        const nextLinkQueryParams = providers.nextLink.substring(providers.nextLink.indexOf('?'))
        const queryParams = new URLSearchParams(nextLinkQueryParams)
        setPage((prevPage) => prevPage += 1)
        setPagesOffset(queryParams.get('_getpagesoffset') || '')
        setFhirPageState(queryParams.get('_getpages') || '')
    }

    const handlePrevPage = () => {
        const previousLinkQueryParams = providers.previousLink.substring(providers.previousLink.indexOf('?'))
        const queryParams = new URLSearchParams(previousLinkQueryParams)
        setPage((prevPage) => prevPage -= 1)
        setPagesOffset(queryParams.get('_getpagesoffset') || '')
        setFhirPageState(queryParams.get('_getpages') || '')
    }

    useEffect(() => {
        if (pagesOffset && fhirPageState) {
            fetchResources()
        }

        if (page === 0 && pagesOffset === '' && fhirPageState === '' && viewPressed) {
            fetchResources()
            setViewPressed(false)
        }

    }, [pagesOffset, fhirPageState, page, viewPressed])

    const newSearch = () => {
        remove()
        setViewPressed(true)
        setPage(0)
        setPagesOffset('')
        setFhirPageState('')
    }

    const handleLinkClick = (provider: any) => {
        setProvider(provider)
        history.push(`/provider-list/${provider.id}`)
    }

    const handleClearForm = () => {
        setProviderSearchTerms('')
        newSearch()
    }

    const handleUpdateSearchTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProviderSearchTerms(e.target.value)
    }
    return (
        <PermissionGate
            user={user}
            role='admin'
        >
            <Container
                style={{
                    padding: '10px 0',
                }}
            >
                <Tab.Container activeKey={key} onSelect={handleChangeKey} transition={false}>
                    <h2>Provider Directory</h2>
                    <Row>
                        <Col sm={2}>
                            <Nav variant='pills' className='flex-column'>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link style={{ color: 'white' }} eventKey='Organization'>Organizations</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link eventKey='Practitioner'>Practitioners</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link eventKey='InsurancePlan'>Networks</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={10} className='mt-sm-0 mt-3'>
                            <Tab.Content>
                                <Tab.Pane eventKey='Organization'>
                                    {
                                        resourceId ? (
                                            <OrganizationDetail organization={provider} />
                                        ) : (
                                            <OrganizationList
                                                organizations={providers}
                                                clickViewDataLink={handleLinkClick}
                                                searchProviderResource={{ searchTerms: providerSearchTerms, resource: '' }}
                                                page={page}
                                                isFetching={isFetching}
                                                handleNextPage={handleNextPage}
                                                handlePrevPage={handlePrevPage}
                                                isLoading={isLoading}
                                                isSuccess={isSuccess}
                                                clearForm={handleClearForm}
                                                newSearch={newSearch}
                                                updateSearchTerms={handleUpdateSearchTerms}
                                            />
                                        )
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey='Practitioner'>
                                    {
                                        resourceId ? (
                                            <PractitionerDetail practitioner={provider} />
                                        ) : (
                                            <PractitionerList
                                                practitioners={providers}
                                                clickViewDataLink={handleLinkClick}
                                                searchProviderResource={{ searchTerms: providerSearchTerms, resource: '' }}
                                                page={page}
                                                isFetching={isFetching}
                                                handleNextPage={handleNextPage}
                                                handlePrevPage={handlePrevPage}
                                                isLoading={isLoading}
                                                isSuccess={isSuccess}
                                                clearForm={handleClearForm}
                                                newSearch={newSearch}
                                                updateSearchTerms={handleUpdateSearchTerms}
                                            />
                                        )
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey='InsurancePlan'>
                                    <NetworkList
                                        networks={providers}
                                        page={page}
                                        isFetching={isFetching}
                                        handleNextPage={handleNextPage}
                                        handlePrevPage={handlePrevPage}
                                        isLoading={isLoading}
                                        isSuccess={isSuccess}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </PermissionGate>
    )
}

export default isAuthenticated(ProviderList)