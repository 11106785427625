import React, { FC, useEffect, useState } from 'react'
import { ExplanationOfBenefitBundle } from '../../../../types/FHIRTypes/Bundle'
import { Row, Col, Spinner } from 'react-bootstrap'
import { buildEOBBillableStart, buildEOBInsurer, buildEOBOutcome, buildEOBDiagnosis, buildEOBPayment, buildEOBBillableEnd, buildEOBCreatedDate, buildEOBPayor, buildEOBPatientPayment } from '../../../../utils/fhirUtils/eob'
import { handleCodeableConcept } from '../../../../utils/helpers'
import useFhirResource from '../../../../hooks/admin/useFhirResource'
import EobItem from '../EobItem'
import { appConfig } from '../../../../assets/customizations/config'
import { useHistory } from 'react-router-dom'

interface Props {
  eobDetailId: string;
  eobData: ExplanationOfBenefitBundle;
  isFetching: boolean;
}

const EobDetail: FC<Props> = ({ eobDetailId, eobData, isFetching }) => {
  const [eob, setEob] = useState<any>(null)

  const history = useHistory()

  useEffect(() => {
    if (!eobData) return

    const eobEntry = eobData?.entry?.find((eob) => eob.resource.id === eobDetailId)
    if (eobEntry) {
      setEob(eobEntry.resource)
    } else {
      history.push('/404')
    }
  }, [eobData])

  // org stuff
  const organization = eob?.contained?.[0]?.payor?.[0]?.reference ?? 'no insurer'
  const trimmedOrg = organization?.split('/')[1]
  const { data: orgData, refetch: getOrg, isFetching: fetchingOrgs } = useFhirResource(trimmedOrg, 'Organization')

  useEffect(() => {
    if (trimmedOrg) {
      getOrg()
    }
  }, [trimmedOrg])

  return (
    <>
      {
        isFetching && (
          <div className='d-flex dataContainer'>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
            <span style={{ marginLeft: '10px' }}>Loading EOB Data...</span>
          </div>
        )
      }
      <dl className='dataContainer'>
        {
          eob ? (
            <Row>
              <Col sm={3} className='mt-3'>
                <dt>Claim ID</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{eob.identifier?.[0].value}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Type</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{handleCodeableConcept(eob.type)}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>SubType</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{eob.subType ? handleCodeableConcept(eob.subType) : ''}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Claim Status</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{eob.status.toUpperCase()}</dd>
              </Col>
              {
                eob.billablePeriod && (
                  <>
                    <Col sm={3} className='mt-3'>
                      <dt>Service Period Start</dt>
                    </Col>
                    <Col sm={9} className='mt-3'>
                      <dd>{buildEOBBillableStart(eob.billablePeriod)}</dd>
                    </Col>
                    <Col sm={3} className='mt-3'>
                      <dt>Service Period End</dt>
                    </Col>
                    <Col sm={9} className='mt-3'>
                      <dd>{buildEOBBillableEnd(eob.billablePeriod)}</dd>
                    </Col>
                  </>
                )
              }
              <Col sm={3} className='mt-3'>
                <dt>Created Date</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{buildEOBCreatedDate(eob.created)}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Insurer</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                {fetchingOrgs ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    size="sm"
                  />
                ) : (
                  <dd>{buildEOBInsurer(orgData)}</dd>
                )}
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Insurer Paid</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{eob.payment && buildEOBPayment(eob.payment)}</dd>
              </Col>
              {
                appConfig.use_eob_member_responsibility && (
                  <>
                    <Col sm={3} className='mt-3'>
                      <dt>Member Responsibility</dt>
                    </Col>
                    <Col sm={9} className='mt-3'>
                      <dd>{buildEOBPatientPayment(eob)}</dd>
                    </Col>
                  </>
                )
              }
              <Col sm={3} className='mt-3'>
                <dt>Payor</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{buildEOBPayor(eob)}</dd>
              </Col>
              {
                eob.outcome && (
                  <>
                    <Col sm={3} className='mt-3'>
                      <dt>Outcome</dt>
                    </Col>
                    <Col sm={9} className='mt-3'>
                      <dd>{buildEOBOutcome(eob.outcome)}</dd>
                    </Col>
                  </>
                )
              }
              {
                eob.diagnosis && (
                  <>
                    <Col sm={3} className='mt-3'>
                      <dt>Diagnosis</dt>
                    </Col>
                    <Col sm={9} className='mt-3'>
                      {buildEOBDiagnosis(eob.diagnosis)}
                    </Col></>
                )
              }
              {
                handleCodeableConcept(eob.type) === 'professional' ? (
                  <>
                    <Col sm={3} className='mt-3' >
                      <dt>Practitioner</dt>
                    </Col>
                    <Col sm={9} className='mt-3'>
                      {eob.contained && eob.contained[1]?.name[0]?.family}
                    </Col>
                  </>
                ) : handleCodeableConcept(eob.type) === 'institutional' ? (
                  <>
                    <Col sm={3} className='mt-3'>
                      <dt>Institutional</dt>
                    </Col>
                    <Col sm={9} className='mt-3'>
                      {eob.contained && eob.contained[2].name}
                    </Col>
                  </>
                ) : null
              }
              <Col sm={3} className='mt-3'>
                <dt>Items</dt>
              </Col>
              <Col sm={9}>
                <EobItem patientEob={eob} />
              </Col>
            </Row>
          ) : null
        }
      </dl>
    </>
  )
}

export default EobDetail