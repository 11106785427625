import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { handleCodeableConcept } from '../../../../utils/helpers'
import { useHistory } from 'react-router-dom'

interface MedicationDetailProps {
    medicationDetailId: any;
    medicationData: any;
    isFetching: boolean;
}

const MedicationDetail: React.FC<MedicationDetailProps> = ({ medicationDetailId, medicationData, isFetching }) => {
    const [medication, setMedication] = useState<any>(null)

    const history = useHistory()
    useEffect(() => {
        if (!medicationData) return

        const foundMedication = medicationData.entry.find((a: any) => a.resource.id === medicationDetailId)
        if (foundMedication) {
            setMedication(foundMedication)
        } else {
            history.push('/404')
        }
    }, [medicationData])

    if (isFetching) {
        return (
            <div>
                <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                />
                Loading Medication...
            </div>
        )
    }

    const showStatusReason = (medication: any) => {
        if (!medication.statusReasonCodeableConcept && !medication.statusReasonReference) return ''

        if (medication.statusReasonCodeableConcept) return handleCodeableConcept(medication.statusReasonCodeableConcept)

        return medication.statusReasonReference.reference
    }

    const showMedication = (medication: any) => {
        if (!medication.medicationCodeableConcept && !medication.medicationReference) return ''

        if (medication.medicationCodeableConcept) return handleCodeableConcept(medication.medicationCodeableConcept)

        return medication.medicationReference?.display ?? ''
    }

    return (
        <dl className='dataContainer'>
            {
                medication && (
                    <>
                        <Row>
                            <Col sm={3}>
                                <dt>Id</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource.id}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Medication</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{showMedication(medication.resource)}</dd>
                            </Col>

                            <Col sm={3}>
                                <dt>Subject</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource?.subject?.display}</dd>
                            </Col>
                            <Col sm={3} className='font-weight-bold'>
                                Dispense Request:
                            </Col>
                            <Col sm={9} className='border p-3 rounded'>
                                <Row>
                                    <Col xs={6}>
                                        Initial Fill Quantity:
                                    </Col>
                                    <Col xs={6}>
                                        {medication?.resource?.dispenseRequest?.initialFill?.quantity?.value} {medication?.resource?.dispenseRequest?.initialFill?.quantity?.unit}
                                    </Col>
                                    <Col xs={6}>
                                        Initial Fill Duration:
                                    </Col>
                                    <Col xs={6}>
                                        {medication?.resource?.dispenseRequest?.initialFill?.duration?.value}
                                    </Col>
                                    <Col xs={6}>
                                        Dispense Interval:
                                    </Col>
                                    <Col xs={6}>
                                        {medication?.resource?.dispenseRequest?.dispenseInterval?.value}
                                    </Col>
                                    <Col xs={6}>
                                        Validity Period:
                                    </Col>
                                    <Col xs={6}>
                                        {medication?.resource?.dispenseRequest?.validityPeriod?.start} {medication?.resource?.dispenseRequest?.validityPeriod?.end}
                                    </Col>
                                    <Col xs={6}>
                                        Number Of Repeats Allowed:
                                    </Col>
                                    <Col xs={6}>
                                        {medication?.resource?.dispenseRequest?.numberOfRepeatsAllowed}
                                    </Col>
                                    <Col xs={6}>
                                        Quantity:
                                    </Col>
                                    <Col xs={6}>
                                        {medication?.resource?.dispenseRequest?.quantity?.value} {medication?.resource?.dispenseRequest?.quantity?.unit}
                                    </Col>
                                    <Col xs={6}>
                                        Expected Supply Duration:
                                    </Col>
                                    <Col xs={6}>
                                        {medication?.resource?.dispenseRequest?.expectedSupplyDuration?.value}
                                    </Col>
                                    <Col xs={6}>
                                        Performer:
                                    </Col>
                                    <Col xs={6}>
                                        {medication?.resource?.dispenseRequest?.performer?.display}
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <dt>Intent</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource?.intent}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Priorty</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource?.priorty}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Status</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource.status}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Status Reason</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{showStatusReason(medication.resource)}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Supporting Information</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.reference?.supportingInformation.map((part: any) => part?.reference).join(', ')}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Performer</dt>
                            </Col>
                            <Col sm={9}>
                                {medication.resource?.performer?.display ?? ''}
                            </Col>
                            <Col sm={3}>
                                <dt>Category</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{handleCodeableConcept(medication.resource.category)}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Encounter</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource.encounter?.display ?? ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Authored On</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource.authoredOn ?? ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Requester</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource.requester?.display ?? ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Performer</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource.performer?.display ?? ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Performer Type</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{handleCodeableConcept(medication.resource.performerType)}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Recorder</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource.recorder?.display ?? ''}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Reason Code</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource?.reasonCode?.map((code: any) => handleCodeableConcept(code)).join(', ')}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Reason Reference</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource?.reasonReference?.map((reason: any) => reason?.display ?? '').join(', ')}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Based On</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource?.basedOn?.map((base: any) => base?.display ?? '').join(', ')}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Course Of Therapy Type</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{handleCodeableConcept(medication.resource.courseOfTherapyType)}</dd>
                            </Col>
                            <Col sm={3}>
                                <dt>Insurance</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{medication.resource?.insurance?.map((insurance: any) => insurance?.display ?? '').join(', ')}</dd>
                            </Col>
                            <Col sm={3} className='font-weight-bold'>
                                Substitution:
                            </Col>
                            <Col sm={9} className='border rounded p-3'>
                                <Row>
                                    <Col xs={3}>
                                        Was Substituted:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.resource?.substitution?.wasSubstituted ? 'Yes' : 'No'}
                                    </Col>
                                    <Col xs={3}>
                                        Type:
                                    </Col>
                                    <Col xs={9}>
                                        {handleCodeableConcept(medication?.resource?.substitution?.type)}
                                    </Col>
                                    <Col xs={3}>
                                        Reason:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.resource?.substitution?.reason?.map((reason: any) => handleCodeableConcept(reason)).join(', ')}
                                    </Col>
                                    <Col xs={3}>
                                        Responsible Party:
                                    </Col>
                                    <Col xs={9}>
                                        {medication?.resource?.substitution?.responsibleParty?.map((party: any) => party.reference).join(', ')}
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={3} className='my-3'>
                                <dt>Notes</dt>
                            </Col>
                            <Col sm={9}>
                                {
                                    medication.resource?.note?.map((note: any) => (
                                        <dd key={note.authorReference.reference}>{note.authorReference.reference}: {note.text}</dd>
                                    ))
                                }
                            </Col>
                            <Col sm={3}>
                                <dt>Dosage Instructions</dt>
                            </Col>
                            <Col sm={9}>
                                {
                                    medication.resource?.dosageInstruction?.map((dosage: any) => (
                                        <Card key={`dosage-instructions-${dosage.sequence}`}>
                                            <Card.Header>{dosage?.text}</Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12} className='font-weight-bold'>
                                                        Timing
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Row>
                                                            <Col xs={6}>
                                                                Frequency:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing.repeat.frequency}
                                                            </Col>
                                                            <Col xs={6}>
                                                                Frequency Max:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing.repeat.frequencyMax}
                                                            </Col>
                                                            <Col xs={6}>
                                                                Count:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing.repeat?.count}
                                                            </Col>
                                                            <Col xs={6}>
                                                                Count Max:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing.repeat?.countMax}
                                                            </Col>
                                                            <Col xs={6}>
                                                                Duration:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing.repeat?.duration}
                                                            </Col>
                                                            <Col xs={6}>
                                                                Duration Max:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing.repeat?.durationMax}
                                                            </Col>
                                                            <Col xs={6}>
                                                                Duration Unit:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing.repeat?.durationUnit}
                                                            </Col>
                                                            <Col xs={6}>
                                                                Period:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing.repeat?.period}
                                                            </Col>
                                                            <Col xs={6}>
                                                                Period Max:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing.repeat?.periodMax}
                                                            </Col>
                                                            <Col xs={6}>
                                                                Period Unit:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing.repeat?.periodUnit}
                                                            </Col>
                                                            <Col xs={6}>
                                                                Days Of Week:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing?.repeat?.daysOfWeek?.map((day: any) => day.code).join(', ')}
                                                            </Col>
                                                            <Col xs={6}>
                                                                Time Of Day:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing?.repeat?.timeOfDay?.map((day: any) => day).join(', ')}
                                                            </Col>
                                                            <Col xs={6}>
                                                                When:
                                                            </Col>
                                                            <Col xs={6}>
                                                                {dosage?.timing?.repeat?.when?.map((day: any) => day.code).join(', ')}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className='mt-3'>
                                                    <Col xs={6}>
                                                        <Row>
                                                            <Col>
                                                                As Needed:
                                                            </Col>
                                                            <Col>
                                                                {dosage?.asNeededBoolean ? 'Yes' : 'No'}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Row>
                                                            <Col>
                                                                Site:
                                                            </Col>
                                                            <Col>
                                                                {handleCodeableConcept(dosage?.site)}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Row>
                                                            <Col>
                                                                Route:
                                                            </Col>
                                                            <Col>
                                                                {handleCodeableConcept(dosage?.route)}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Row>
                                                            <Col>
                                                                Method:
                                                            </Col>
                                                            <Col>
                                                                {handleCodeableConcept(dosage?.method)}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} className='font-weight-bold'>
                                                        Dose and Rate:
                                                    </Col>
                                                    <Col xs={12}>
                                                        {
                                                            dosage?.doseAndRate?.map((doseAndRate: any, index: number) => (
                                                                <Row key={`dose-and-rate-${index}`}>
                                                                    <Col xs={6}>
                                                                        Type:
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        {handleCodeableConcept(doseAndRate.type)}
                                                                    </Col>
                                                                    {
                                                                        doseAndRate.doseRange && (
                                                                            <>
                                                                                <Col xs={6}>
                                                                                    Dose Range Low:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate.doseRange.low.value}
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    Dose Range High:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate.doseRange.high.value}
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        doseAndRate?.doseQuantity && (
                                                                            <>
                                                                                <Col xs={6}>
                                                                                    Dose Quantity:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate?.doseQuantity.value} {doseAndRate?.doseQuantity.unit}
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        doseAndRate.rateRatio && (
                                                                            <>
                                                                                <Col xs={6}>
                                                                                    Rate Ratio Numerator:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate.rateRatio.numerator.value}
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    Rate Ratio Denominator:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate.rateRatio.denominator.value}
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        doseAndRate?.rateRange && (
                                                                            <>
                                                                                <Col xs={6}>
                                                                                    Rate Range Low:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate?.rateRange.low.value} {doseAndRate?.rateRange.low.unit}
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    Rate Range High:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate?.rateRange.high.value} {doseAndRate?.rateRange.high.unit}
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        doseAndRate?.rateQuantity && (
                                                                            <>
                                                                                <Col xs={6}>
                                                                                    Rate Quantity:
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    {doseAndRate?.rateQuantity.value}
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                </Row>
                                                            ))
                                                        }
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    ))
                                }
                            </Col>
                        </Row>
                    </>
                )
            }
        </dl>
    )
}

export default MedicationDetail
