import React, { FC, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
// import { formatDateWithFormat } from '../../../../utils/helpers'
import { handleCodeableConcept } from '../../../../utils/helpers'
import { ObservationBundle } from '../../../../types/FHIRTypes/Bundle'
import Smoking from '../Smoking'
import { Observation, ObservationComponent } from '../../../../types/FHIRTypes/Observation'
import { useHistory } from 'react-router-dom'

interface Props {
  detailId: string | undefined;
  observationData: ObservationBundle;
  patientData: any;
}

const ObservationDetail: FC<Props> = ({ detailId, observationData, patientData }: Props) => {
  const [observationResource, setObservationResource] = React.useState<Observation | undefined>(undefined)

  const history = useHistory()
  useEffect(() => {
    if (!observationData) return

    const observationEntry = observationData.entry?.find((observation: any) => observation.resource.id === detailId)
    if (observationEntry) {
      setObservationResource(observationEntry.resource)
    } else {
      history.push('/404')
    }
  }, [observationData])

  if (observationResource && observationResource.code && handleCodeableConcept(observationResource.code) === 'Tobacco smoking status') {
    return <Smoking resource={observationResource} patientData={patientData} />
  }

  const handleObservationEffectiveDate = (observation: Observation | undefined) => {
    if (!observation) return ''

    if (observation.effectiveDateTime) {
      return new Date(observation.effectiveDateTime).toLocaleDateString()
    }

    if (observation.effectivePeriod) {
      const startDate = observation.effectivePeriod.start || ''
      const endDate = observation.effectivePeriod.end || ''
      return endDate
        ? `${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`
        : new Date(startDate).toLocaleDateString()
    }

    return ''
  }

  const handleObservationValue = (observation: Observation | ObservationComponent | undefined) => {
    if (!observation) return ''

    const { valueQuantity, valueCodeableConcept, valueString, valueBoolean, valueInteger, valueRange, valueRatio, valueSampledData, valueTime, valueDateTime, valuePeriod } = observation

    if (valueQuantity) {
      return `${valueQuantity.value} ${valueQuantity.unit}`
    }

    if (valueCodeableConcept) {
      return handleCodeableConcept(valueCodeableConcept)
    }

    if (valueString) {
      return valueString
    }

    if (valueBoolean !== undefined) {
      return valueBoolean ? 'Yes' : 'No'
    }

    if (valueInteger !== undefined) {
      return valueInteger.toString()
    }

    if (valueRange) {
      return `${valueRange.low.value} ${valueRange.low?.unit} - ${valueRange.high.value} ${valueRange.high?.unit}`
    }

    if (valueRatio) {
      return `${valueRatio.numerator.value} ${valueRatio.numerator.unit} : ${valueRatio.denominator.value} ${valueRatio.denominator.unit}`
    }

    if (valueSampledData) {
      return `${valueSampledData.origin?.value} ${valueSampledData.origin?.unit} - ${valueSampledData.period}`
    }

    if (valueTime) {
      return valueTime
    }

    if (valueDateTime) {
      return new Date(valueDateTime).toLocaleDateString()
    }

    if (valuePeriod) {
      const startDate = valuePeriod.start || ''
      const endDate = valuePeriod.end || ''
      return endDate ? `${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}` : `${new Date(startDate).toLocaleDateString()}`
    }

    return ''
  }

  return (
    <>
      <dl className='dataContainer'>
        <Row>
          <Col sm={3}>
            <dt>Status</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.status}</dd>
          </Col>
          <Col sm={3}>
            <dt>Code</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.code ? handleCodeableConcept(observationResource?.code) : ''}</dd>
          </Col>
          <Col sm={3}>
            <dt>Effective Date</dt>
          </Col>
          <Col sm={9}>
            <dd>{handleObservationEffectiveDate(observationResource)}</dd>
          </Col>
          <Col sm={3}>
            <dt>Id</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.id}</dd>
          </Col>
          <Col sm={3}>
            <dt>Based On</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.basedOn?.map((base) => base.display).join(', ')}</dd>
          </Col>
          <Col sm={3}>
            <dt>Part Of</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.partOf?.map((part) => part.display).join(', ')}</dd>
          </Col>
          <Col sm={3}>
            <dt>Category</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.category?.map((category) => handleCodeableConcept(category)).join(', ')}</dd>
          </Col>
          <Col sm={3}>
            <dt>Subject</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.subject?.display}</dd>
          </Col>
          <Col sm={3}>
            <dt>Focus</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.focus?.map((focus) => focus.display).join(', ')}</dd>
          </Col>
          <Col sm={3}>
            <dt>Encounter</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.encounter?.display}</dd>
          </Col>
          <Col sm={3}>
            <dt>Issued</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.issued ? new Date(observationResource.issued).toLocaleDateString() : ''}</dd>
          </Col>
          <Col sm={3}>
            <dt>Performer</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.performer?.map((performer) => performer.display).join(', ')}</dd>
          </Col>
          <Col sm={3}>
            <dt>Value</dt>
          </Col>
          <Col sm={9}>
            <dd>{handleObservationValue(observationResource)}</dd>
          </Col>
          <Col sm={3}>
            <dt>Data Absent Reason</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.dataAbsentReason ? handleCodeableConcept(observationResource.dataAbsentReason) : ''}</dd>
          </Col>
          <Col sm={3}>
            <dt>Interpretation</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.interpretation?.map((interpretation) => handleCodeableConcept(interpretation)).join(', ')}</dd>
          </Col>
          <Col sm={3}>
            <dt>Notes</dt>
          </Col>
          <Col sm={9}>
            <dd>
              {
                observationResource?.note?.map((note, index) => (
                  <Card key={`note-${index}`}>
                    <Card.Header>
                      {note.authorReference?.display || note.authorString} - {note.time ? new Date(note.time).toLocaleDateString() : ''}
                    </Card.Header>
                    <Card.Body>
                      {note.text}
                    </Card.Body>
                  </Card>
                ))
              }
            </dd>
          </Col>
          <Col sm={3}>
            <dt>Body Site</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.bodySite ? handleCodeableConcept(observationResource.bodySite) : ''}</dd>
          </Col>
          <Col sm={3}>
            <dt>Method</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.method ? handleCodeableConcept(observationResource.method) : ''}</dd>
          </Col>
          <Col sm={3}>
            <dt>Specimen</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.specimen?.display || ''}</dd>
          </Col>
          <Col sm={3}>
            <dt>Measurement Device</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.device?.display || ''}</dd>
          </Col>
          <Col sm={3}>
            <dt>Reference Range(s)</dt>
          </Col>
          <Col sm={9}>
            <dd>
              {
                observationResource?.referenceRange?.map((range, index) => (
                  <div key={`reference-range-${index}`} className='border rounded p-3 mb-3'>
                    <Row>
                      <Col xs={6}>
                        Low
                      </Col>
                      <Col xs={6}>
                        {range?.low?.value} {range?.low?.unit}
                      </Col>
                      <Col xs={6}>
                        High
                      </Col>
                      <Col xs={6}>
                        {range?.high?.value} {range?.high?.unit}
                      </Col>
                      <Col xs={6}>
                        Type
                      </Col>
                      <Col xs={6}>
                        {handleCodeableConcept(range.type)}
                      </Col>
                      <Col xs={6}>
                        Applies To
                      </Col>
                      <Col xs={6}>
                        {range.appliesTo?.map((appliesTo) => handleCodeableConcept(appliesTo)).join(', ')}
                      </Col>
                      <Col xs={6}>
                        Age
                      </Col>
                      <Col xs={6}>
                        {range.age?.low?.value} - {range.age?.high?.value}
                      </Col>
                      <Col xs={6}>
                        Text
                      </Col>
                      <Col xs={6}>
                        {range.text}
                      </Col>
                    </Row>
                  </div>
                ))
              }
            </dd>
          </Col>
          <Col sm={3}>
            <dt>Has Member</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.hasMember?.map((member) => member.display).join(', ')}</dd>
          </Col>
          <Col sm={3}>
            <dt>Derived From</dt>
          </Col>
          <Col sm={9}>
            <dd>{observationResource?.derivedFrom?.map((derivedFrom) => derivedFrom.display).join(', ')}</dd>
          </Col>
          <Col sm={3}>
            <dt>Component(s)</dt>
          </Col>
          <Col sm={9}>
            <dd>
              {
                observationResource?.component?.map((component, index) => (
                  <div key={`component-${index}`} className='border rounded p-3 mb-3'>
                    <Row>
                      <Col xs={6}>
                        Code
                      </Col>
                      <Col xs={6}>
                        {handleCodeableConcept(component.code)}
                      </Col>
                      <Col xs={6}>
                        Value
                      </Col>
                      <Col xs={6}>
                        {handleObservationValue(component)}
                      </Col>
                      <Col xs={6}>
                        Data Absent Reason
                      </Col>
                      <Col xs={6}>
                        {component.dataAbsentReason ? handleCodeableConcept(component?.dataAbsentReason) : ''}
                      </Col>
                      <Col xs={6}>
                        Interpretation
                      </Col>
                      <Col xs={6}>
                        {component.interpretation?.map((interpretation) => handleCodeableConcept(interpretation)).join(', ')}
                      </Col>
                      <Col xs={6}>
                        Reference Range(s)
                      </Col>
                      <Col xs={6}>
                        {
                          component?.referenceRange?.map((range, index) => (
                            <div key={`component-reference-range-${index}`} className='border rounded p-3 mb-3'>
                              <Row>
                                <Col xs={6}>
                                  Low
                                </Col>
                                <Col xs={6}>
                                  {range?.low?.value} {range.low?.unit}
                                </Col>
                                <Col xs={6}>
                                  High
                                </Col>
                                <Col xs={6}>
                                  {range?.high?.value} {range?.high?.unit}
                                </Col>
                                <Col xs={6}>
                                  Type
                                </Col>
                                <Col xs={6}>
                                  {handleCodeableConcept(range?.type)}
                                </Col>
                                <Col xs={6}>
                                  Applies To
                                </Col>
                                <Col xs={6}>
                                  {range?.appliesTo?.map((appliesTo) => handleCodeableConcept(appliesTo)).join(', ')}
                                </Col>
                                <Col xs={6}>
                                  Age
                                </Col>
                                <Col xs={6}>
                                  {range.age?.low?.value} - {range.age?.high?.value}
                                </Col>
                                <Col xs={6}>
                                  Text
                                </Col>
                                <Col xs={6}>
                                  {range.text}
                                </Col>
                              </Row>
                            </div>
                          ))
                        }
                      </Col>
                    </Row>
                  </div>
                ))
              }
            </dd>
          </Col>
        </Row>
      </dl>
    </>
  )
}

export default ObservationDetail