import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatDateInTimeZone } from '../../../../utils/helpers'
import { formatEobDataForTable } from '../../../../utils/fhirUtils/eob'
import PaginationButtons from '../../../PaginationButtons'
import { ExplanationOfBenefitBundle } from '../../../../types/FHIRTypes/Bundle'


interface Props {
  eobData: ExplanationOfBenefitBundle;
  isFetching: boolean;
  handleChangePage: (url: string) => void;
}

const EobList: FC<Props> = ({ eobData, handleChangePage, isFetching }: Props) => {
  const patientEobs = formatEobDataForTable(eobData)

  const nextLink = eobData && eobData.link.find((l: any) => l.relation === 'next')
  const previousLink = eobData && eobData.link.find((l: any) => l.relation === 'previous')


  return (
    <>
      <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
        {patientEobs.length > 0 &&
          <div className='mb-2'>
            <PaginationButtons
              backDisabled={!previousLink}
              nextDisabled={!nextLink}
              onBackClick={() => previousLink && handleChangePage(previousLink.url)}
              onNextClick={() => nextLink && handleChangePage(nextLink.url)}
            />
          </div>
        }
        {eobData?.total > 0 ? `${eobData.total.toLocaleString()} record(s) found` : ''}
      </div>
      <Table size='sm' hover>
        <thead>
          <tr>
            {/* <th scope="col">Member ID</th> */}
            <th className='d-table-cell'>Claim ID</th>
            {/* <th scope="col">Payor</th> */}
            <th className='d-table-cell'>Type</th>
            <th className='d-table-cell'>Billable Start</th>
            <th className='d-none d-lg-table-cell'>Billable End</th>
            <th className='d-none d-lg-table-cell'>View Detail</th>
          </tr>
        </thead>
        <tbody>
          {
            isFetching ? (
              <tr>
                <td colSpan={6} rowSpan={15} className='d-flex'>
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                  <span style={{ marginLeft: '10px' }}>Loading Member EOBs...</span>
                </td>
              </tr>
            ) : patientEobs.length === 0 ? (
              <tr>
                <td colSpan={6} rowSpan={15} className='text-center'>
                  No Explanation Of Benefits found for this member.
                </td>
              </tr>
            ) : (
              <>
                {
                  patientEobs.map((eob) => (
                    <tr key={eob.eobId}>
                      {/* <th scope="row">{eob.memberId}</th> */}
                      <td className='d-table-cell'><Link to={`/patient/EOBs/${eob.eobId}`}>{eob.claimId}</Link></td>
                      {/* <td scope='col'>{eob.payor}</td> */}
                      <td className='d-table-cell'>{eob.type}</td>
                      <td className='d-table-cell'>{eob.billableStart ? formatDateInTimeZone(eob.billableStart, 'MM/dd/yyyy', 'UTC') : ''}</td>
                      <td className='d-none d-lg-table-cell'>{eob.billableEnd ? formatDateInTimeZone(eob.billableEnd, 'MM/dd/yyyy', 'UTC') : ''}</td>
                      <td className='d-none d-lg-table-cell'><Link to={`/patient/EOBs/${eob.eobId}`}>View Detail</Link></td>
                    </tr>
                  ))
                }
              </>
            )
          }
        </tbody>
        <tfoot>
          {patientEobs.length > 0 &&
            <tr>
              <td scope="row" colSpan={5}>
                <PaginationButtons
                  backDisabled={!previousLink}
                  nextDisabled={!nextLink}
                  onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                  onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                />
              </td>
            </tr>
          }
        </tfoot>
      </Table>
    </>
  )
}

export default EobList
