import React from 'react'
import ProcedureDetail from './ProcedureDetail'
import ProcedureList from './ProcedureList'
import { ProcedureBundle } from '../../../types/FHIRTypes/Bundle'
import { buildPatientName } from '../../../utils/fhirUtils/patient'

interface ProcedureProps {
    patientData: any;
    page: string | undefined;
    isFetching: boolean;
    procedureData: ProcedureBundle;
    procedureDetailId: string | undefined;
}

const Procedure: React.FC<ProcedureProps> = ({ patientData, procedureDetailId, procedureData, isFetching, page }) => {
    return (
        <>
            <h3>Procedures</h3>
            <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>

            {
                procedureDetailId && page === 'Procedures' ? (
                    <ProcedureDetail procedureData={procedureData} isFetching={isFetching} procedureDetailId={procedureDetailId} />
                ) : <ProcedureList procedureData={procedureData} isFetching={isFetching} />
            }
        </>
    )
}

export default Procedure