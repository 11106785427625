import React from 'react'
import { ProviderResource } from '../../../../types/ProviderResource'
import { Col, Row } from 'react-bootstrap'
import { capitalizeFirstLetter, handleCodeableConcept } from '../../../../utils/helpers'
import PractitionerRole from '../PractitionerRole'

interface PractitionerDetailProps {
    practitioner: ProviderResource | null
}

const PractitionerDetail: React.FC<PractitionerDetailProps> = ({ practitioner }) => {

    return (
        <section>
            <h3>Practitioners</h3>
            <dl className='dataContainer'>
                <Row>
                    <Col sm={3}>
                        <dt>Name</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{practitioner?.name}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>NPI</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{practitioner?.identifiers && practitioner?.identifiers[0].value.split('-')[1]}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Gender</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{capitalizeFirstLetter(practitioner?.gender)}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Birth Date</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{practitioner?.birthDate ? new Date(practitioner.birthDate).toLocaleDateString() : ''}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Address</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{practitioner?.address}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Contact Details</dt>
                    </Col>
                    <Col sm={9}>
                        {
                            practitioner?.telecom?.map((telecom, index) => (
                                <dd key={index}>
                                    {telecom.system}: {telecom.value}
                                </dd>
                            ))
                        }
                    </Col>
                    <Col sm={3}>
                        <dt>Languages</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {practitioner?.communications?.map((communication) => handleCodeableConcept(communication)).join(', ')}
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Qualifications</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                practitioner?.qualification?.map((qualification, index) => (
                                    <div key={index} className='border rounded p-3'>
                                        <Row>
                                            <Col>
                                                Code
                                            </Col>
                                            <Col>
                                                {handleCodeableConcept(qualification.code)}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                Period
                                            </Col>
                                            <Col>
                                                {qualification.period.start}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                Issuer
                                            </Col>
                                            <Col>
                                                {qualification.issuer.display}
                                            </Col>
                                        </Row>
                                    </div>
                                ))
                            }
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Networks</dt>
                    </Col>
                    <Col sm={9}>
                        <Row>
                            <Col>
                                <dt>Name</dt>
                            </Col>
                            <Col>
                                <dt>Practitioner Specialty</dt>
                            </Col>
                        </Row>
                        {
                            practitioner?.roles?.map((role) => (
                                <PractitionerRole key={role.id} role={role} />
                            ))
                        }
                    </Col>
                </Row>
            </dl>
        </section>
    )
}

export default PractitionerDetail