import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { formatDateInTimeZone, formatDateWithFormat } from '../../../../utils/helpers'
import { handleCodeableConcept } from '../../../../utils/helpers'
import { useHistory } from 'react-router-dom'

interface Props {
    conditionDetailId: any;
    conditionData: any;
    isFetching: boolean;
}


const ConditionDetail: FC<Props> = ({ conditionDetailId, conditionData, isFetching }: Props) => {
    const [patientCondition, setPatientCondition] = useState<any>(null)

    const history = useHistory()

    useEffect(() => {
        if (!conditionData) return

        const foundCondition = conditionData.entry.find((condition: any) => condition.resource.id === conditionDetailId)
        if (foundCondition) {
            setPatientCondition(foundCondition)
        } else {
            history.push('/404')
        }
    }, [conditionData])

    return (
        isFetching ? (
            <tr>
                <td colSpan={6} rowSpan={15} className='d-flex'>
                    <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    />
                    <span style={{ marginLeft: '10px' }}>Loading Member Diagnoses...</span>
                </td>
            </tr>
        ) : patientCondition ? (
            <>
                <dl className='dataContainer'>
                    <Row>
                        <Col sm={3}>
                            <dt>Category</dt>
                        </Col>
                        <Col sm={9}>
                            {handleCodeableConcept(patientCondition?.resource?.category[0])}
                        </Col>
                        <Col sm={3}>
                            <dt>Code</dt>
                        </Col>
                        <Col sm={9}>
                            {handleCodeableConcept(patientCondition?.resource?.code)}
                        </Col>
                        <Col sm={3}>
                            <dt>Date Recorded</dt>
                        </Col>
                        <Col sm={9}>
                            {formatDateWithFormat(patientCondition?.resource?.recordedDate, 'MM/dd/yyyy') ?? ''}
                        </Col>

                        <Col sm={3}>
                            <dt>Asserter</dt>
                        </Col>
                        <Col sm={9}>
                            {patientCondition?.resource?.asserter?.reference ?? ''}
                        </Col>
                        <Col sm={3}>
                            <dt>Clinical Status</dt>
                        </Col>
                        <Col sm={9}>
                            {handleCodeableConcept(patientCondition?.resource?.clinicalStatus)}
                        </Col>
                        <Col sm={3}>
                            <dt>Verification Status</dt>
                        </Col>
                        <Col sm={9}>
                            {handleCodeableConcept(patientCondition?.resource?.verificationStatus)}
                        </Col>
                        <Col sm={3}>
                            <dt>Severity</dt>
                        </Col>
                        <Col sm={9}>
                            {handleCodeableConcept(patientCondition?.resource?.severity)}
                        </Col>
                        <Col sm={3}>
                            <dt>Body Site</dt>
                        </Col>
                        <Col sm={9}>
                            {handleCodeableConcept(patientCondition?.resource?.bodySite?.[0])}
                        </Col>
                        <Col sm={3}>
                            <dt>Last Updated</dt>
                        </Col>
                        <Col sm={9}>
                            {formatDateInTimeZone(patientCondition?.resource?.meta?.lastUpdated, 'MM/dd/yyyy', 'UTC') ?? ''}
                        </Col>
                    </Row>
                </dl>
            </>

        ) :
            <tr>
                <td colSpan={6} rowSpan={15} className='text-center'>
                    No Immunizations Details found for this member.
                </td>
            </tr>
    )
}


export default ConditionDetail