import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { buildPatientId } from '../../../../utils/fhirUtils/patient'
import { formatDateInTimeZone, formatDateWithFormat } from '../../../../utils/helpers'
import { handleCodeableConcept } from '../../../../utils/helpers'
import { useHistory } from 'react-router-dom'


interface Props {
  detailId: string;
  immunizationData: any;
  patientData: any;
  isFetching: boolean;
}

const ImmunizationDetail: FC<Props> = ({ detailId, immunizationData, patientData, isFetching }: Props) => {
  const [immunization, setImmunization] = useState<any>(null)

  const history = useHistory()

  useEffect(() => {
    if (!immunizationData) return

    const foundImmunization = immunizationData.entry.find((immunization: any) => immunization.resource.id === detailId)
    if (foundImmunization) {
      setImmunization(foundImmunization)
    } else {
      history.push('/404')
    }
  }, [immunizationData])

  return (
    <section>
      {
        isFetching ? (
          <tr>
            <td colSpan={6} rowSpan={15} className='d-flex'>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
              <span style={{ marginLeft: '10px' }}>Loading Member Immunizations...</span>
            </td>
          </tr>
        ) : immunization ? (
          <>
            <dl className='dataContainer'>
              <Row>
                <Col sm={3}>
                  <dt>Status</dt>
                </Col>
                <Col sm={9}>
                  {immunization.resource.status.toUpperCase()}
                </Col>

                <Col sm={3}>
                  <dt>Code</dt>
                </Col>
                <Col sm={9}>
                  {/* {immunization.resource.vaccineCode.text} */}
                  {handleCodeableConcept(immunization.resource.vaccineCode)}
                </Col>

                <Col sm={3}>
                  <dt>Occurence Date</dt>
                </Col>
                <Col sm={9}>
                  {formatDateInTimeZone(immunization.resource.occurrenceDateTime, 'MM/dd/yyyy', 'UTC')}
                </Col>
                <Col sm={3}>
                  <dt>Encounter</dt>
                </Col>
                <Col sm={9}>
                  {immunization?.resource?.encounter?.reference ?? ''}
                </Col>
                <Col sm={3}>
                  <dt>Location</dt>
                </Col>
                <Col sm={9}>
                  {immunization?.resource?.location?.reference ?? ''}
                </Col>
                <Col sm={3}>
                  <dt>Manufacturer</dt>
                </Col>
                <Col sm={9}>
                  {immunization?.resource?.manufacturer?.display ?? ''}
                </Col>
                <Col sm={3}>
                  <dt>Lot Number</dt>
                </Col>
                <Col sm={9}>
                  {immunization?.resource?.lotNumber ?? ''}
                </Col>

                <Col sm={3}>
                  <dt>Expiration Date</dt>
                </Col>
                <Col sm={9}>
                  {formatDateWithFormat(immunization?.reference?.expirationDate, 'MM/dd/yyyy') ?? ''}
                </Col>

                <Col sm={3}>
                  <dt>Application Site</dt>
                </Col>
                <Col sm={9}>
                  {/* {immunization?.resource?.site?.coding[0]?.display ?? ''} */}
                  {handleCodeableConcept(immunization?.resource?.site)}
                </Col>

                <Col sm={3}>
                  <dt>Route</dt>
                </Col>
                <Col sm={9}>
                  {handleCodeableConcept(immunization?.resource?.route)}
                </Col>

                <Col sm={3}>
                  <dt>Immunization ID</dt>
                </Col>
                <Col sm={9}>
                  {detailId}
                </Col>

                {/* <Col sm={3}>
                <dt>Vaccine</dt>
              </Col>
              <Col sm={9}>
                {immunization.resource.vaccineCode.coding[0].code} - {immunization.resource.vaccineCode.coding[0].display} ({immunization.resource.vaccineCode.text})
              </Col> */}

                <Col sm={3}>
                  <dt>Notes</dt>
                </Col>
                <Col sm={9}>
                </Col>

              </Row>
            </dl>
          </>
        ) :
          <tr>
            <td colSpan={6} rowSpan={15} className='text-center'>
              No Immunizations Details found for this member.
            </td>
          </tr>
      }
    </section>
  )
}

export default ImmunizationDetail