import { differenceInCalendarYears, parseISO } from 'date-fns'
import { format } from 'date-fns-tz'
import { companyMessage } from '../assets/i18n/i18n'
import { CodeableConcept } from '../types/FHIRTypes/CodeableConcept'
import { string } from 'yup'

export const ifExists = (data: any, key: string): string => {
  if (data) {
    return data[0][key]
  }
  return ''
}

export const numberWithCommas = (x: number): string => x !== undefined ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''

export const calculateAge = (dob: Date): number => {
  const today = new Date()
  const birthDate = new Date(dob)
  let age = differenceInCalendarYears(today, birthDate)
  const monthDifference = today.getMonth() - birthDate.getMonth()
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1
  }
  return age
}

export const prepareValueForDb = (string: string): string | null => string || null

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url)
  } catch (error) {
    return false
  }

  return true
}

export const capitalizeFirstLetter = (string: string | undefined): string => {
  let newString = ''
  if (string) {
    newString = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }
  return newString
}

export const formatDate = (date: string): string => date ? format(new Date(date), 'MM/dd/yyyy') : ''

export const formatDateWithFormat = (date: string, fmt: string): string => date ? format(new Date(date), fmt) : ''

export const formatDateInTimeZone = (date: string, fmt: string, tz: string): string => format(parseISO(date), fmt, { timeZone: tz })

export const sum = (a: number, b: number): number => a + b

export const getUrlParameter = (name: string, url: string): string => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(url)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const formatDollar = (value: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  return formatter.format(value)
}

export function buildPhone(providerPhone: any): Array<string> {
  const phone = providerPhone
  if (phone?.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }
  return phone
}

export const determineEnvironment = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'https://18.233.54.217:9090/fhir/metadata' : process.env.REACT_APP_ENVIRONMENT === 'quality' ? 'http://44.193.85.216:9090/fhir/metadata' : 'https://cchp.healthlx.com:9090/fhir/metadata'

export const determineEnvironmentProvider = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'https://18.233.54.217:9091/fhir/metadata' : process.env.REACT_APP_ENVIRONMENT === 'quality' ? 'http://44.193.85.216:9091/fhir/metadata' : 'https://cchp.healthlx.com:9091/fhir/metadata'

export const getToken = (): string => {
  let token = ''
  const usertoken = sessionStorage.getItem('auth-token')
  if (usertoken) {
    token = JSON.parse(usertoken)
  }
  return token
}

export const testStringWithRegex = (str: string | undefined, regex: RegExp): boolean => str ? regex.test(str) : false

export const addCompanyPhoneMessage = (str: string): string => str ? str.replace('<phone>', companyMessage.ContactPhone) : ''

export const handleCodeableConcept = (codeableConcept: CodeableConcept): string => {
  return codeableConcept?.coding?.[0]?.display || codeableConcept?.text || codeableConcept?.coding?.[0]?.code || ''
}

export const scrollToTop = (): void => window.scrollTo(0, 0)