import React, { FC, useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { Link, useLocation, useHistory } from 'react-router-dom'
import useLogoutUser from '../../hooks/authentication/useLogoutUser'
import useUser from '../../hooks/authentication/useUser'

import client_logo from '../../assets/customizations/logos/client_banner.svg'

import portalBanner from '../../assets/customizations/logos/member_portal_banner.svg'


import '../../App.scss'
import { useRole } from '../../hooks/authentication/useRole'

const Header: FC = () => {
  const [activeTab, setActiveTab] = useState('')
  const [showSecondaryNav, setShowSecondaryNav] = useState(true)
  const location = useLocation()

  useEffect(() => {
    switch (location.pathname) {
      case '/':
      case '/admin':
      case '/login':
        setActiveTab('home')
        break
      case '/help':
        setActiveTab('help')
        break
      case '/my-account':
        setActiveTab('my-account')
        break
      case '/available-apps':
        setActiveTab('available-apps')
        break
      case '/register/member':
      case '/register/requestor':
      case '/register/contact':
      case '/register/validate':
      case '/register/create':
        setActiveTab('register')
        break
      default:
        setActiveTab('')
        break
    }

    setShowSecondaryNav(
      !location.pathname.includes('/developers') &&
      location.pathname !== '/app-register' &&
      !location.pathname.includes('/app-edit')
    )
  }, [location.pathname])

  const { data: user, remove: removeUser, status } = useUser()

  const { role, setRole } = useRole()

  const [homeLink, setHomeLink] = useState({ text: 'Home', destination: '/' })

  useEffect(() => {
    if (role) {
      setHomeLink({
        text: role === 'admin' ? 'Admin Home' : 'Home',
        destination: '/',
      })
    }
  }, [role])

  const logoutUser = useLogoutUser()
  const history = useHistory()
  const handleLogoutUser = () => {
    logoutUser.mutate({ refreshToken: user.refresh_token })
  }

  useEffect(() => {
    if (logoutUser.isSuccess) {
      removeUser()
      setRole('member')
      history.push('/login')
    }
  }, [logoutUser.isSuccess])

  return (
    <header >
      <Link to={homeLink.destination}>
        <div className="client_banner">
          <p>
            <Image
              src={client_logo}
              className="img-fluid"
              alt="Health LX" />
          </p>
        </div>

        <div className="portal_banner">

          <Image
            src={portalBanner}
            className="img-fluid"
            alt="Member Portal"
          />
        </div>

      </Link>
      {showSecondaryNav && status !== 'loading' && (
        <nav id="main_nav" aria-label="main navigation">
          <ul className="nav justify-content-center">
            <li className="nav-item">
              <Link
                className={`nav-link${activeTab === 'home' ? ' active-link' : ' inactive-link'}`}
                to={user ? homeLink.destination : '/login'}
              >
                {homeLink.text}
              </Link>
            </li>

            {!user && (
              <>
                <li className="nav-item">
                  <Link
                    className={`nav-link${activeTab === 'help' ? ' active-link' : ' inactive-link'}`}
                    to="/help"
                  >
                    Help
                  </Link>
                </li>
                <li className="nav-item">
                  <Link

                    className={`nav-link${activeTab === 'available-apps' ? ' active-link' : ' inactive-link'}`}
                    to="/available-apps"
                  >
                    Available Apps
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link${activeTab === 'register' ? ' active-link' : ' inactive-link'}`}
                    to="/register/requestor"
                  >
                    Register
                  </Link>
                </li>
              </>
            )}

            {user && (
              <>
                <li className="nav-item">
                  <Link

                    className={`nav-link${activeTab === 'my-account' ? ' active-link' : ' inactive-link'}`}
                    to="/my-account"
                  >
                    My Account
                  </Link>
                </li>
                <li className="nav-item">
                  <Link

                    className={`nav-link${activeTab === 'available-apps' ? ' active-link' : ' inactive-link'}`}
                    to="/available-apps"
                  >
                    Available Apps
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link${activeTab === 'help' ? ' active-link' : ' inactive-link'}`}
                    to="/help"
                  >
                    Help
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link inactive-link" to="#" onClick={handleLogoutUser}>
                    Log Out
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      )}
    </header>
  )
}

export default Header


